import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAU-oVqMV_Z2i1jLEA0u12L-js0AfyMbGs",
    authDomain: "josefina-memories.firebaseapp.com",
    databaseURL: "https://josefina-memories.firebaseio.com",
    projectId: "josefina-memories",
    storageBucket: "josefina-memories.appspot.com",
    messagingSenderId: "587217266344",
    appId: "1:587217266344:web:cb8e5cd865df67ff25c49d",
    measurementId: "G-Y947HZM8PT"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const memoryRef = db.collection('memories');

export {db, memoryRef};
<template>
  <div class="bg-white">
    <h1 class="uppercase text-black text-gray-600">Butterfly Selection Form</h1>
    <hr class="mt-3">
    <br>
    <h3 class="p-5 uppercase text-center">Select a butterfly</h3>

    <div class="overflow-y-auto max-h-96 grid grid-cols-4 gap-4">
      <div>
        <input id="bf1" v-model="bfId" class="hidden" name="bf_option" type="radio" value="1"> <label for="bf1">
        <img alt="Butterfly_1" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 1)}" src="/butterflies/1.png">
      </label>
      </div>
      <div>
        <input id="bf2" v-model="bfId" class="hidden" name="bf_option" type="radio" value="2"> <label for="bf2">
        <img alt="Butterfly_2" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 2)}" src="/butterflies/2.png">
      </label>
      </div>
      <div>
        <input id="bf3" v-model="bfId" class="hidden" name="bf_option" type="radio" value="3"> <label for="bf3">
        <img alt="Butterfly_3" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 3)}" src="/butterflies/3.png">
      </label>
      </div>
      <div>
        <input id="bf4" v-model="bfId" class="hidden" name="bf_option" type="radio" value="4"> <label for="bf4">
        <img alt="Butterfly_4" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 4)}" src="/butterflies/4.png">
      </label>
      </div>
      <div>
        <input id="bf5" v-model="bfId" class="hidden" name="bf_option" type="radio" value="5"> <label for="bf5">
        <img alt="Butterfly_5" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 5)}" src="/butterflies/5.png">
      </label>
      </div>
      <div>
        <input id="bf6" v-model="bfId" class="hidden" name="bf_option" type="radio" value="6"> <label for="bf6">
        <img alt="Butterfly_6" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 6)}" src="/butterflies/6.png">
      </label>
      </div>
      <div>
        <input id="bf7" v-model="bfId" class="hidden" name="bf_option" type="radio" value="7"> <label for="bf7">
        <img alt="Butterfly_7" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 7)}" src="/butterflies/7.png">
      </label>
      </div>
      <div>
        <input id="bf8" v-model="bfId" class="hidden" name="bf_option" type="radio" value="8"> <label for="bf8">
        <img alt="Butterfly_8" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 8)}" src="/butterflies/8.png">
      </label>
      </div>
      <div>
        <input id="bf9" v-model="bfId" class="hidden" name="bf_option" type="radio" value="9"> <label for="bf9">
        <img alt="Butterfly_9" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 9)}" src="/butterflies/9.png">
      </label>
      </div>
      <div>
        <input id="bf10" v-model="bfId" class="hidden" name="bf_option" type="radio" value="10"> <label for="bf10">
        <img alt="Butterfly_10" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 10)}" src="/butterflies/10.png">
      </label>
      </div>
      <div>
        <input id="bf11" v-model="bfId" class="hidden" name="bf_option" type="radio" value="11"> <label for="bf11">
        <img alt="Butterfly_11" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 11)}" src="/butterflies/11.png">
      </label>
      </div>
      <div>
        <input id="bf12" v-model="bfId" class="hidden" name="bf_option" type="radio" value="12"> <label for="bf12">
        <img alt="Butterfly_12" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 12)}" src="/butterflies/12.png">
      </label>
      </div>
      <div>
        <input id="bf13" v-model="bfId" class="hidden" name="bf_option" type="radio" value="13"> <label for="bf13">
        <img alt="Butterfly_13" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 13)}" src="/butterflies/13.png">
      </label>
      </div>
      <div>
        <input id="bf14" v-model="bfId" class="hidden" name="bf_option" type="radio" value="14"> <label for="bf14">
        <img alt="Butterfly_14" class="w-full hover:opacity-50 opacity-30 m-auto sm:w-full" :class="{checked : (bfId == 14)}" src="/butterflies/14.png">
      </label>
      </div>
    </div>
    <div class="flex">
      <Button text="NEXT" @click="nextForm"/>
    </div>
  </div>

</template>

<script>
import Button from '@/components/elements/Button'

export default {
  data() {
    return {
      bfId: null,
      bfIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    }
  },
  methods:{
    nextForm(){
      if(this.bfId){
        this.$store.commit('setModal', 'MESSAGE FORM')
      }else{
        alert('Please select a butterfly before going to the next page.');
      }
    }
  },
  watch:{
    bfId: function (val){
      this.updateNewMessage('butterFly', val);
    }
  },
  props:{
    updateNewMessage: Function
  },
  components: {
    Button
  }
}
</script>
<style lang="scss" scoped>
.checked, .checked:hover {
  opacity: 1;
}
</style>
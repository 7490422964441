<template>
  <Canvas />
  <NameTag />
  <SideBar />
</template>

<script>
import Canvas from "@/components/modules/Canvas";
import NameTag from "@/components/modules/NameTag";
import SideBar from "@/components/modules/SideBar";
export default {
  mounted() {
    this.$store.dispatch('init');
  },
  components: {
    Canvas,
    NameTag,
    SideBar
  },
};
</script>
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './main.scss'
import LogRocket from 'logrocket';
LogRocket.init('josefina/josefina');

createApp(App).use(store).use(router).mount('#app')

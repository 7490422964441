<template>
  <Nav class="hidden" />
  <Modal />
  <router-view/>
</template>
<script>
import Nav from '@/components/modules/Nav'
import Modal from '@/components/modules/Modal'

export default {
  
  components: {
    Nav,
    Modal
  }
}
</script>

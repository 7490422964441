<template>
  <div class="bg-white">
    <h1 class="uppercase text-black text-gray-600">Updating Message</h1>
    <hr class="mt-3">
    <h3 class="p-5 uppercase text-center">Message</h3>
    <div class="overflow-y-auto max-h-96">
            <textarea
                id="edit-message"
                v-model="message"
                class="bg-gray-100"
                placeholder="Please provide your message here...">
      </textarea>
    </div>
    <h3 class="p-5 uppercase text-center">Author</h3>
    <div class="overflow-y-auto max-h-96">
      <label for="fullname" class="block p-5">Your Full Name or Nickname: </label>
      <input class="block p-5 bg-gray-100 w-full" type="text" id="fullname" v-model="fullName">
      <label class="block p-5" for="email">Your Email:</label>
      <input class="block p-5 bg-gray-100 w-full" type="text" id="email" v-model="email">
    </div>
    <div class="flex p-5">
      <Button text="Update" @click="updateMsg"/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'

export default {
  name: "EditForm",
  data(){
    return {
      message: this.$store.getters.editingMsg.message,
      fullName: this.$store.getters.editingMsg.fullName,
      email: this.$store.getters.editingMsg.email
    }
  },
  methods:{
    updateMsg(){
      console.log('Test: ', this.messageText);
      let pl_messageText = this.message;
      let pl_fullName = this.fullName;
      let pl_email = this.email;

      this.$store.dispatch('updateMemory', {
        message: pl_messageText,
        fullName: pl_fullName,
        email: pl_email
      });
      this.$store.commit('closeMessageModal');
    }
  },
  components: {Button}
}
</script>

<style lang="scss" scoped>
#edit-message{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 200px;
  min-width: 100%;
}
</style>
<template>
  <!--  modal absolute w-full h-full top-0 left-0 flex items-center justify-center bg-opacity-50  -->
  <div class="relative z-50 bg-black bg-opacity-50 w-screen h-screen" :class="{ hidden: !$store.state.modal.visible }"
       @click.self="$store.commit('closeMessageModal')">
    <div class="relative w-screen h-screen md:p-16" @click.self="$store.commit('closeMessageModal')">
      <div id="modal"
           class="max-h-full relative m-auto md:max-w-4xl w-full h-full lg:h-auto bg-white rounded-md shadow-lg justify-center text-2xl p-5">
        <Close class="absolute right-5 top-5" @click="$store.commit('closeMessageModal')"/>
        <ButterflyForm
            :updateNewMessage="updateNewMessage"
            v-if="$store.state.modal.content === 'BUTTERFLY SELECTION'"/>
        <MessageForm :updateNewMessage="updateNewMessage" v-else-if="$store.state.modal.content === 'MESSAGE FORM'"/>
        <AuthorForm
            :updateNewMessage="updateNewMessage"
            :submitNewMessage="submitNewMessage"
            v-else-if="$store.state.modal.content === 'AUTHOR'"/>
        <SuccessForm v-else-if="$store.state.modal.content === 'SUCCESS'"/>
        <FailForm v-else-if="$store.state.modal.content === 'FAIL'"/>
        <EditForm v-else-if="$store.state.modal.content === 'EDIT'"/>
        <div v-else>
          Wooooops!! Something went wrong :'(
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '@/components/elements/Close'
import ButterflyForm from '@/components/modules/modalForms/ButterflyForm'
import MessageForm from '@/components/modules/modalForms/MessageForm'
import AuthorForm from '@/components/modules/modalForms/AuthorForm'
import SuccessForm from "./modalForms/SuccessForm";
import FailForm from "./modalForms/FailForm";
import EditForm from "./modalForms/EditForm";

export default {
  data() {
    return {
      newMessage: {
        butterFly: null,
        message: null,
        fullName: null,
        email: null
      }
    }
  },
  methods: {
    /* This method is passed as a prop and called within the child component to update the parent's data.newMessage */
    updateNewMessage(key, value) {
      this.newMessage[key] = value;
    },
    submitNewMessage() {
      this.$store.dispatch('addMemory', this.newMessage);
    }
  },
  components: {
    Close,
    ButterflyForm,
    MessageForm,
    AuthorForm,
    SuccessForm,
    FailForm,
    EditForm
  }
};
</script>

<style lang="scss" scoped>

</style>

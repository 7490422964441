<template>
    <span class="preview p-0 float-left w-1/5 mr-4">
      <img
        :src="'/butterflies/' + butterfly + '.png'"
        class="full-img img-responsive align-middle b-0"
        alt="butterfly"
      />
    </span>
</template>

<script>
    export default {
        props:['butterfly']
    }
</script>
<template>
  <div class="bg-white">
    <h1 class="uppercase text-black text-gray-600">Form Result</h1>
    <hr class="mt-3">
    <br>
    <h3 class="p-5 uppercase text-center">Ohhh noo!</h3>
    <div class="overflow-y-auto max-h-96">
      <h4>Something went wrong.</h4>
      Sorry, we were unable to process your message.  Please try again later
    </div>
    <div class="flex p-5">
      <Button text="CLOSE" @click="$store.commit('closeMessageModal')"/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'

export default {
  name: "authorForm",
  components: {Button}
}

</script>

<template>
  <div
    id="nameTag"
    class="absolute left-16 bg-blur bottom-16 bg-black bg-opacity-50 p-3 rounded-md m-0 block transition-all"
  >
    <div
      id="avatar"
      class="h-28 w-28 bg-center bg-cover bg-no-repeat border-4 border-white rounded-md float-left"
    ></div>

    <div id="mGardenContent" class="content float-left ml-6">
      <ul class="text-white list-none m-0 p-0">
        <li class="text-3xl">Josefina Mejia Galingan</li>
        <li>Jossie, Leoning, Lola Ganda</li>
        <li>1938 - 2020</li>
        <li>Palmdale, CA</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NameTag",
};
</script>

<style lang="scss" scoped>
#avatar {
  background-image: url("~@/assets/images/lola.png");
}
</style>
import {createStore} from 'vuex'
import {memoryRef} from './db'


export default createStore({
    state: {
        authored: [],
        authLevel: 0,
        modal: {
            editing: null,
            visible: false,
            content: 'NONE'
        },
        memories: []
    },
    mutations: {
        openModal(state) {
            state.modal.visible = true;
        },
        setModal(state, content) {
            state.modal.content = content;
        },
        closeMessageModal(state) {
            state.modal.visible = false;
        },
        setMemoryList(state, data) {
            state.memories = data;
        },
        setAuthored(state, data) {
            state.authored = data ? JSON.parse(data) : [];
        },
        editMsg(state, id) {
            state.memories.forEach((v, i) => {
                if (v.id === id) {
                    state.modal.content = 'EDIT';
                    state.modal.visible = true;
                    state.modal.editing = id;
                }
            })
        },
        updateMemory(state, pl){
            state.memories.forEach(memory => {
                if(memory.id === state.modal.editing){
                    memory.message = pl.message;
                    memory.fullName = pl.fullName;
                    memory.email = pl.email;
                }
            })
        }

    },
    actions: {
        openModal({commit}, content) {
            commit('setModal', content);
            commit('openModal',);
        },
        setModal({commit}, content) {
            commit('setModal', content);
        },
        addMemory({commit}, memory) {
            memory.time = Date.now();
            memoryRef.add(memory)
                .then((docRef) => {
                    commit('setModal', 'SUCCESS');
                    let currentList = JSON.parse(localStorage.getItem('authored')) ? JSON.parse(localStorage.getItem('authored')) : [];
                    currentList.push(docRef.id);
                    currentList = JSON.stringify(currentList);
                    localStorage.setItem('authored', currentList);
                    commit('setAuthored', currentList);
                    console.log("Document written with ID: ", docRef.id);
                })
                .catch((error) => {
                    commit('setModal', 'FAIL');
                    console.error("Error adding document: ", error);
                });
        },
        updateMemory({commit, state}, pl){
            console.log(state.modal);
            console.log('pl', pl);
            memoryRef.doc(state.modal.editing).set(pl, { merge: true });
            commit('updateMemory', pl);
        },
        init({commit}) {
            memoryRef.orderBy("time", "desc").get().then(snapShot => {
                let list = [];
                snapShot.docs.map(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    console.log(data.id);
                    list.push(data);
                });
                commit('setMemoryList', list);
                console.log(list);
                commit('setAuthored', localStorage.getItem('authored'));
            })
        }
    },
    getters: {
        editingMsg: state => {
            return (state.memories.filter( memory => {
                return memory.id === state.modal.editing;
            })[0]);
        }
    },
    modules: {}
});

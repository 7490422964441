<template>
  <div
    class="p-5 ml-1 mr-1 mt-5 mb-3 border-b-2 border-gray-500"
    v-for="memory in this.$store.state.memories"
    :key="memory.butterFly"
  >
    <div class="overflow-hidden">
      <div class="text-gray-900 text-sm leading-4 m-0">
        <Butterfly :butterfly="memory.butterFly" />
        {{ memory.message }}
      </div>
      <ul class="list-none p-0 pt-5 m-0">
        <li class="block text-sm text-right">
          <span v-if="memory.fullName">{{memory.fullName}}</span>
          <span v-else>{{ memory.firstName }} {{ memory.lastName }}</span>

        </li>
        <li v-if="memory.time" class="block text-xs text-right">{{ new Date(memory.time).toDateString()}}</li>
        <li v-else-if="memory.date" class="block text-xs text-right">{{ memory.date }}</li>
        <Button
            v-if="$store.state.authored.includes(memory.id)"
            text="EDIT"
            class="pt-1 pb-1 text-sm opacity-50 hover:opacity-100"
            @click="$store.commit('editMsg', memory.id)"
            ></Button>
      </ul>
    </div>
    <br class="clear" />
  </div>
</template>
<script>
import Butterfly from "@/components/elements/Butterfly";
import Button from "@/components/elements/Button";
export default {

  components: { Butterfly, Button },
};
</script>

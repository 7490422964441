<template>
  <div class="bg-white">
    <h1 class="uppercase text-black text-gray-600">Author Form</h1>
    <hr class="mt-3">
    <br>
    <h3 class="p-5 uppercase text-center">Please provide your contact information</h3>
    <div class="overflow-y-auto max-h-96">
      <label for="fullname" class="block p-5">Your Full Name or Nickname: </label>
      <input class="block p-5 bg-gray-100 w-full" type="text" id="fullname" v-model="fullName">
      <label class="block p-5" for="email">Your Email:</label>
      <input class="block p-5 bg-gray-100 w-full" type="text" id="email" v-model="email">
    </div>
    <div class="flex p-5">
      <Button text="BACK" @click="$store.commit('setModal', 'MESSAGE FORM')"/>
      <Button text="SEND" @click="nextForm"/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'

export default {
  name: "authorForm",
  data() {
    return {
      fullName: null,
      email: null
    }
  },
  methods:{
    nextForm(){
      if(this.fullName == null || this.email == null){
        alert('Please provide your name/nickname and email.');
      }else{
        this.submitNewMessage();
      }
    }
  },
  components: {
    Button
  }
  ,
  watch: {
    fullName: function (val) {
      this.updateNewMessage('fullName', val);
    },
    email: function (val) {
      this.updateNewMessage('email', val);
    }
  },
  props: {
    submitNewMessage: Function,
    updateNewMessage: Function
  }
}
</script>

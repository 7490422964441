<template>
  <div class="bg-white">
    <h1 class="uppercase text-black text-gray-600">Form Result</h1>
    <hr class="mt-3">
    <br>
    <h3 class="p-5 uppercase text-center">Thank you.</h3>
    <div class="overflow-y-auto max-h-96">
      <h4>Your message has been sent.</h4>
      Please refresh this page to see your message on the list.
    </div>
    <div class="flex p-5">
      <Button text="CLOSE" @click="closereload "/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'

export default {
  name: "authorForm",
  methods:{
    closereload(){
      this.$store.commit('closeMessageModal');
      location.reload();
    }
  },
  components: {Button}
}

</script>

<template>
  <div>
    <div class="border-2 border-gray-500 relative w-9 h-9 rounded-full text-2xl text-center text-gray-500 hover:text-white hover:bg-gray-500 cursor-pointer">
      <span>✖</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
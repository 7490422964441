<template>
  <div class="bg-white">
    <h1 class="uppercase text-black text-gray-600">Message Form</h1>
    <hr class="mt-3">
    <br>
    <h3 class="p-5 uppercase text-center">Provide your message</h3>

    <div class="overflow-y-auto max-h-96">
      <textarea
          id="message"
          v-model="messageText"
          class="bg-gray-100"
          placeholder="Please provide your message here...">
      </textarea>
    </div>
    <div class="flex">
      <Button text="BACK" @click="$store.commit('setModal', 'BUTTERFLY SELECTION')"/>
      <Button text="NEXT" @click="nextForm"/>
    </div>

  </div>

</template>

<script>
import Button from "@/components/elements/Button";

export default {
  data(){
    return {
      messageText: null
    }
  },
  methods:{
    nextForm(){
      if(this.messageText){
        this.$store.commit('setModal', 'AUTHOR');
      }else{
        alert('Please provide your message first before moving to the next page.');
      }
    }
  },
  components: {
    Button
  },
  watch: {
    messageText: function (val) {
      this.updateNewMessage('message', val);
    }
  },
  props:{
    updateNewMessage: Function
  },
}
</script>

<style lang="scss" scoped>
#message{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 200px;
  min-width: 100%;
}
</style>
<template>
  <div
    class="bg-white bg-opacity-50 bg-blur absolute top-0 right-0 h-screen transition-all p-0 m-0 w-screen md:w-96 w-screen"
  >
    <div class="wrapper w-100 h-screen z-20">
      <div class="relative h-screen z-10 opacity-100 left-auto bottom-auto">
        <div class="relative bg-transparent h-screen overflow-y-scroll clear pb-16">
          <p class="title text-blue-700 font-bold text-center text-xl">
            Memories and Messages
          </p>
          <Memories />
          <div class="fixed bottom-0 w-full p-3">
            <Button class="block" text="Add a message" @click="$store.dispatch('openModal', 'BUTTERFLY SELECTION')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Memories from "@/components/modules/Memories";
import Button from "@/components/elements/Button";
export default {
  name: "SideBar",
  methods: {
    openMessageForm() {
      this.$store.commit("openMessageModal");
    },
  },
  components: { Memories, Button },
};
</script>
